import React, { Component } from 'react';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from '../../components/Home/Home';
import Prizes from '../../components/Prizes/Prizes';
import SpaceAdventure from '../../components/SpaceAdventure/SpaceAdventure';
import HauntedHouse from '../../components/HauntedHouse';
import ReactGA from 'react-ga';
import ContactUs from '../../components/ContactUs/ContactUs';

class Playground extends Component { 

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    const { cookies } = props;

    const userCookie = cookies.get('user') || {
      firstName: '',
      surname: '',
      country: '',
      email: '',
      ifWin: true,
      moreInfo: false
    };

    this.state = {
      hasError: false,
      showGameSelector: false,
      showSubmitScore: false,
      user: userCookie
    };
  }

  handleToggleGameSelector = () => {
    console.log('[Playground.jsx] handleToggleGameSelector')
    this.setState({showGameSelector: !this.state.showGameSelector})
  }

  handleToggleSubmitScore = () => {
    console.log('[Playground.jsx] handleToggleSubmitScore')
    this.setState({showSubmitScore: !this.state.showSubmitScore})
  }

  handleSetUser = (user) => {
    console.log('[Playground.jsx] handleSetUser')
    const { cookies } = this.props;
    this.setState({user});
    cookies.set('user', JSON.stringify(user), { path: '/', expires:new Date('2020-10-30')});
  }

  render () {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    ReactGA.pageview('/');
    return (
      <Router>
        <Route path="/hauntedhouse" exact>
          <HauntedHouse
            toggleGameSelector={this.handleToggleGameSelector}
            showGameSelector={this.state.showGameSelector}
            toggleSubmitScore={this.handleToggleSubmitScore}
            showSubmitScore={this.state.showSubmitScore}
            user={this.state.user}
            setUser={this.handleSetUser}
          />
        </Route>
        <Route path="/spaceadventure" exact>
          <SpaceAdventure
            toggleGameSelector={this.handleToggleGameSelector}
            showGameSelector={this.state.showGameSelector}
            toggleSubmitScore={this.handleToggleSubmitScore}
            showSubmitScore={this.state.showSubmitScore}
            user={this.state.user}
            setUser={this.handleSetUser}
          />
        </Route>
        <Route path="/contact" exact>
          <ContactUs
            toggleGameSelector={this.handleToggleGameSelector}
            showGameSelector={this.state.showGameSelector}
          />
        </Route>
        <Route path="/" exact>
          <Home
            toggleGameSelector={this.handleToggleGameSelector}
            showGameSelector={this.state.showGameSelector}
          />
          <Prizes />
        </Route>
      </Router>
    );
  }
}

export default withCookies(Playground);
