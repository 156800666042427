import React from 'react';
import Header from '../Header/Header';
import SelectGame from '../SelectGame/SelectGame';
//import { Test } from './ContactUs.styles';



const ContactUs = (props) => {

  return (
  <div className="ContactUsWrapper">
    <Header toggleGameSelector={props.toggleGameSelector}/>
    <div className="bg-white">
      <div className="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-base leading-6 font-semibold text-blue-600 tracking-wide uppercase">For TC20 by The Information Lab</h1>
          <p className="mt-1 text-4xl leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl">Want to chat? Get in contact.</p>
          <p className="max-w-xl mt-5 mx-auto text-xl leading-7 text-gray-500">Whether it's to find out more about The Information Lab or let us know how you're liking the Data Playground.</p>
        </div>
      </div>
    </div>
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="grid grid-cols-1 gap-16">
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <h2 className="text-2xl leading-8 font-extrabold text-gray-900 sm:text-3xl sm:leading-9">
              Get in touch
            </h2>
            <div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
              <div>
                <h4 className="text-lg leading-6 font-medium text-gray-900">
                  Data Playground Feedback
                </h4>
                <dl className="mt-2 text-base leading-6 text-gray-500">
                  <div>
                    <dt className="sr-only">
                      Email
                    </dt>
                    <dd>
                      <a href="mailto:info@theinformationlab.co.uk" target="_blank" rel="noopener noreferrer">info@theinformationlab.co.uk</a>
                    </dd>
                  </div>
                </dl>
              </div>
              <div>
                <h4 className="text-lg leading-6 font-medium text-gray-900">
                  Created by
                </h4>
                <dl className="mt-2 text-base leading-6 text-gray-500">
                  <div>
                    <dt className="sr-only">
                      Name
                    </dt>
                    <dd>
                      Craig Bloodworth
                    </dd>
                  </div>
                  <div className="mt-1">
                    <dt className="sr-only">
                      Email
                    </dt>
                    <dd>
                      <a href="mailto:craig.bloodworth@theinformationlab.co.uk" target="_blank" rel="noopener noreferrer">craig.bloodworth@theinformationlab.co.uk</a>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
          <div className="border-t-2 border-gray-100"></div>
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <h2 className="text-2xl leading-8 font-extrabold text-gray-900 sm:text-3xl sm:leading-9">
              Locations
            </h2>
            <div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
              <div>
                <h4 className="text-lg leading-6 font-medium text-gray-900">
                  United Kingdom
                </h4>
                <div className="text-base leading-6 text-gray-500">
                  <p>
                    London
                  </p>
                  <p className="mt-2 flex flex-inline">
                    <svg className="h-6 w-6 ml-1 mr-2 text-orange-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                    </svg>
                    <a href="mailto:info@theinformationlab.co.uk" target="_blank" rel="noopener noreferrer">info@theinformationlab.co.uk</a>
                  </p>
                  <p className="mt-1 flex flex-inline">
                    <svg className="h-6 w-6 ml-1 mr-2 text-orange-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                    </svg>
                    <a href="https://www.theinformationlab.co.uk" target="_blank" rel="noopener noreferrer">www.theinformationlab.co.uk</a>
                  </p>
                </div>
              </div>
              <div>
                <h4 className="text-lg leading-6 font-medium text-gray-900">
                  Ireland
                </h4>
                <div className="text-base leading-6 text-gray-500">
                  <p>
                    Dublin
                  </p>
                  <p className="mt-2 flex flex-inline">
                    <svg className="h-6 w-6 ml-1 mr-2 text-orange-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                    </svg>
                    <a href="mailto:info@theinformationlab.ie" target="_blank" rel="noopener noreferrer">info@theinformationlab.ie</a>
                  </p>
                  <p className="mt-1 flex flex-inline">
                    <svg className="h-6 w-6 ml-1 mr-2 text-orange-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                    </svg>
                    <a href="https://www.theinformationlab.ie" target="_blank" rel="noopener noreferrer">www.theinformationlab.ie</a>
                  </p>
                </div>
              </div>
              <div>
                <h4 className="text-lg leading-6 font-medium text-gray-900">
                  France
                </h4>
                <div className="text-base leading-6 text-gray-500">
                  <p>
                    Paris
                  </p>
                  <p className="mt-2 flex flex-inline">
                    <svg className="h-6 w-6 ml-1 mr-2 text-orange-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                    </svg>
                    <a href="mailto:info@theinformationlab.fr" target="_blank" rel="noopener noreferrer">info@theinformationlab.fr</a>
                  </p>
                  <p className="mt-1 flex flex-inline">
                    <svg className="h-6 w-6 ml-1 mr-2 text-orange-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                    </svg>
                    <a href="https://www.theinformationlab.fr" target="_blank" rel="noopener noreferrer">www.theinformationlab.fr</a>
                  </p>
                </div>
              </div>
              <div>
                <h4 className="text-lg leading-6 font-medium text-gray-900">
                  Italy
                </h4>
                <div className="text-base leading-6 text-gray-500">
                  <p>
                    Milan
                  </p>
                  <p className="mt-2 flex flex-inline">
                    <svg className="h-6 w-6 ml-1 mr-2 text-orange-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                    </svg>
                    <a href="mailto:info@theinformationlab.it" target="_blank" rel="noopener noreferrer">info@theinformationlab.it</a>
                  </p>
                  <p className="mt-1 flex flex-inline">
                    <svg className="h-6 w-6 ml-1 mr-2 text-orange-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                    </svg>
                    <a href="https://www.theinformationlab.it" target="_blank" rel="noopener noreferrer">www.theinformationlab.it</a>
                  </p>
                </div>
              </div>
              <div>
                <h4 className="text-lg leading-6 font-medium text-gray-900">
                  Germany
                </h4>
                <div className="text-base leading-6 text-gray-500">
                  <p>
                    Hamburg
                  </p>
                  <p className="mt-2 flex flex-inline">
                    <svg className="h-6 w-6 ml-1 mr-2 text-orange-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                    </svg>
                    <a href="mailto:info@theinformationlab.de" target="_blank" rel="noopener noreferrer">info@theinformationlab.de</a>
                  </p>
                  <p className="mt-1 flex flex-inline">
                    <svg className="h-6 w-6 ml-1 mr-2 text-orange-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                    </svg>
                    <a href="https://www.theinformationlab.de" target="_blank" rel="noopener noreferrer">www.theinformationlab.de</a>
                  </p>
                </div>
              </div>
              <div>
                <h4 className="text-lg leading-6 font-medium text-gray-900">
                  Netherlands
                </h4>
                <div className="text-base leading-6 text-gray-500">
                  <p>
                  Amsterdam
                  </p>
                  <p className="mt-2 flex flex-inline">
                    <svg className="h-6 w-6 ml-1 mr-2 text-orange-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                    </svg>
                    <a href="mailto:info@theinformationlab.nl" target="_blank" rel="noopener noreferrer">info@theinformationlab.nl</a>
                  </p>
                  <p className="mt-1 flex flex-inline">
                    <svg className="h-6 w-6 ml-1 mr-2 text-orange-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                    </svg>
                    <a href="https://www.theinformationlab.nl" target="_blank" rel="noopener noreferrer">www.theinformationlab.nl</a>
                  </p>
                </div>
              </div>
              <div>
                <h4 className="text-lg leading-6 font-medium text-gray-900">
                  Spain
                </h4>
                <div className="text-base leading-6 text-gray-500">
                  <p>
                  Madrid
                  </p>
                  <p className="mt-2 flex flex-inline">
                    <svg className="h-6 w-6 ml-1 mr-2 text-orange-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                    </svg>
                    <a href="mailto:info@theinformationlab.es" target="_blank" rel="noopener noreferrer">info@theinformationlab.es</a>
                  </p>
                  <p className="mt-1 flex flex-inline">
                    <svg className="h-6 w-6 ml-1 mr-2 text-orange-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                    </svg>
                    <a href="https://www.theinformationlab.es" target="_blank" rel="noopener noreferrer">www.theinformationlab.es</a>
                  </p>
                </div>
              </div>
              <div>
                <h4 className="text-lg leading-6 font-medium text-gray-900">
                  Luxembourg
                </h4>
                <div className="text-base leading-6 text-gray-500">
                  <p className="mt-2 flex flex-inline">
                    <svg className="h-6 w-6 ml-1 mr-2 text-orange-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                    </svg>
                    <a href="mailto:info@theinformationlab.lu" target="_blank" rel="noopener noreferrer">info@theinformationlab.lu</a>
                  </p>
                  <p className="mt-1 flex flex-inline">
                    <svg className="h-6 w-6 ml-1 mr-2 text-orange-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                    </svg>
                    <a href="https://www.theinformationlab.lu" target="_blank" rel="noopener noreferrer">www.theinformationlab.lu</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SelectGame show={props.showGameSelector} toggle={props.toggleGameSelector}/>
  </div>
)};

ContactUs.propTypes = {
  // bla: PropTypes.string,
};

ContactUs.defaultProps = {
  // bla: 'test',
};

export default ContactUs;