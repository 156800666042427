import React from 'react';
import MakeoverMondayImage from '../../img/makeovermonday.jpg';
import TableauPrepImage from '../../img/tableauprep.jpeg';

const Prizes = (props) => (
  <div className="PrizesWrapper">
    <div className="bg-blue-900">
      <div className="relative pt-12 sm:pt-16 lg:pt-24">
        <div className="max-w-screen-xl mx-auto text-center px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto space-y-2 lg:max-w-none">
            <h2 className="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">
              Prizes
            </h2>
            <p className="text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none">
              Play some pinball win some knowledge
            </p>
            <p className="text-xl leading-7 text-gray-300">
              The Information Lab authors Carl Allchin & Andy Kriebel are giving away their books throughout TC
            </p>
          </div>
        </div>
      </div>
      <div className="mt-8 pb-12 bg-gray-100 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
        <div className="relative">
          <div className="absolute inset-0 h-3/4 bg-blue-900 mb-40"></div>
          <div className="relative z-5 max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0">
              <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                  <div className="mt-4 flex items-baseline text-6xl leading-none font-extrabold">
                    Tableau Prep: Up & Running
                  </div>
                  <p className="mt-5 text-lg leading-7 text-gray-500">
                    by Carl Allchin
                  </p>
                </div>
                <div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-100 space-y-6 sm:p-10 sm:pt-6">
                  <div className="mx-auto">
                    <img src={TableauPrepImage} alt="Tableau Prep: Up and Running" />
                  </div>
                  <div className="rounded-md shadow">
                    <a href="https://www.amazon.co.uk/Tableau-Prep-Running-Preparation-Analysis/dp/1492079626/" target="_blank" rel="noopener noreferrer" className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out" aria-describedby="buy-makeovermonday">
                      Want it today? Buy Now
                    </a>
                  </div>
                </div>
              </div>

              <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                  <div className="mt-4 flex items-baseline text-6xl leading-none font-extrabold">
                    #Makeover Monday
                  </div>
                  <p className="mt-5 text-lg leading-7 text-gray-500">
                    by Andy Kriebel & Eva Murray
                  </p>
                </div>
                <div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-100 space-y-6 sm:p-10 sm:pt-6">
                <div className="mx-auto">
                    <img src={MakeoverMondayImage} alt="#Makeover Monday Book" />
                  </div>
                  <div className="rounded-md shadow">
                    <a href="https://www.amazon.co.uk/MakeoverMonday-Improving-Visualize-Analyze-Chart/dp/1119510775" target="_blank" rel="noopener noreferrer" className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out" aria-describedby="buy-makeovermonday">
                      Want it today? Buy Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Prizes;
