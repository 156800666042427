import React, {useCallback, useState, useEffect} from 'react';
import countries from './Countries';
import {useDropzone} from 'react-dropzone'

let urls = new WeakMap();

let blobUrl = blob => {
  if (urls.has(blob)) {
    return urls.get(blob)
  } else {
    let url = URL.createObjectURL(blob)
    urls.set(blob, url)
    return url
  }
}

const ScoreForm = (props) => {

  const [ validForm, setValidForm ] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    console.log('[ScoreForm.jsx] onDrop acceptedFiles', acceptedFiles)
    acceptedFiles.forEach((file) => {
      props.saveCapture(file);
    })
    /* eslint-disable */
  }, []);/* eslint-enable */

  useEffect(() => {
    const valid = props.score.highScore > 0 && props.user.firstName && props.user.surname && props.user.country !== '';
    setValidForm(valid);
  }, [props.user, props.score])

  const {getRootProps, getInputProps} = useDropzone({onDrop, accept: 'image/png,image/jpeg', multiple: false, maxSize: 12000000});

  return (
    <div>
      <div>
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Score
            </h3>
            <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
              Please enter your high score. If you'd like to be able to win a prize please provide a screenshot of your score.
            </p>
          </div>
          <div className="mt-6 sm:mt-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="game" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                Game
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                  <input 
                    id="game"
                    className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    value={props.score ? props.score.game : ''}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="score" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px">
                Score <br/>
                <span className="text-xs text-gray-400">Required</span>
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                  <input 
                    id="score"
                    className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    value={props.score && props.score.highScore ? props.score.highScore : ''}
                    onChange={(e) => props.setScore(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="hidden mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="take-screenshot" className="block text-sm leading-5 font-medium text-gray-700">
                Take Screenshot
              </label>
              <div className="mt-2 sm:mt-0 sm:col-span-2">
                <span className="rounded-md shadow-sm">
                  <button id="take-screenshot" type="button" onClick={() => props.captureScreen()} className="py-2 px-3 border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
                    Capture
                  </button>
                </span>
              </div>
            </div>

            <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="upload-screenshot" className="block text-sm leading-5 font-medium text-gray-700 sm:mt-px sm:pt-2">
                Upload Screenshot
              </label>
              <div className="mt-2 sm:mt-0 sm:col-span-2">
                {!props.score || !props.score.screenshot &&
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                      <div className="text-center">
                        <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48">
                          <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <p className="mt-1 text-sm text-gray-600">
                          <button type="button" className="mr-1 font-medium text-orange-600 hover:text-orange-500 focus:outline-none focus:underline transition duration-150 ease-in-out">
                            Upload a file
                          </button>
                        </p>
                        <p className="mt-1 text-xs text-gray-500">
                          PNG or JPG up to 10MB
                        </p>
                      </div>
                    </div>
                  </div>
                }
                <img className={!props.score || !props.score.screenshot ? 'hidden' : ''} src={props.score && props.score.screenshot ? blobUrl(props.score.screenshot) : ''} alt="Screenshot Preview"/>
              </div>
              
            </div>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-200 pt-8 sm:mt-5 sm:pt-10">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Your Details
            </h3>
            <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
              Please let use know who you are so we can send you a prize if you win.
            </p>
          </div>
          <div className="mt-6 sm:mt-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="first_name" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px">
                First name <br/>
                <span className="text-xs text-gray-400">Required</span>
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                  <input 
                    id="first_name" 
                    className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    value={props.user ? props.user.firstName : ''}
                    onChange={(e) => {
                      props.setUser({
                        ...props.user,
                        firstName: e.target.value
                      });
                    }}
                    />
                </div>
              </div>
            </div>

            <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="last_name" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px">
                Last name <br/>
                <span className="text-xs text-gray-400">Required</span>
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                  <input
                    id="last_name"
                    className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    value={props.user ? props.user.surname : ''}
                    onChange={(e) => {
                      props.setUser({
                        ...props.user,
                        surname: e.target.value
                      });
                    }}
                    />
                </div>
              </div>
            </div>

            <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                Email address
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg rounded-md shadow-sm">
                  <input
                    id="email"
                    type="email"
                    className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    value={props.user ? props.user.email : ''}
                    onChange={(e) => {
                      props.setUser({
                        ...props.user,
                        email: e.target.value
                      });
                    }}
                    />
                </div>
              </div>
            </div>

            <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="country" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px">
                Country / Region <br/>
                <span className="text-xs text-gray-400">Required</span>
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                  <select
                    id="country"
                    className="block form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    value={props.user ? props.user.country : ''}
                    onChange={(e) => {
                      props.setUser({
                        ...props.user,
                        country: e.target.value
                      });
                    }}
                    >
                    <option key={''} value={''}> --- Select Country --- </option>
                    {countries.map((country, idx) => {
                      return ( <option key={country.code} value={country.code}>{country.label}</option> )
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-200 pt-8 sm:mt-5 sm:pt-10">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Get In Touch
            </h3>
            <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
              We don't want to bother you if you're not expecting it. Are you happy for us to contact you?
            </p>
          </div>
          <div className="mt-6 sm:mt-5">
            <div className="sm:border-t sm:border-gray-200 sm:pt-5">
              <div role="group" aria-labelledby="label-email">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                  <div>
                    <div className="text-base leading-6 font-medium text-gray-900 sm:text-sm sm:leading-5 sm:text-gray-700" id="label-email">
                      By Email
                    </div>
                  </div>
                  <div className="mt-4 sm:mt-0 sm:col-span-2">
                    <div className="max-w-lg">
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="ifWin"
                            type="checkbox"
                            className="form-checkbox h-4 w-4 text-orange-600 transition duration-150 ease-in-out"
                            checked={props.user.ifWin}
                            onChange={(e) => {
                              props.setUser({
                                ...props.user,
                                ifWin: !props.user.ifWin
                              });
                            }}/>
                        </div>
                        <div className="ml-3 text-sm leading-5">
                          <label htmlFor="ifWin" className="font-medium text-gray-700">If You Win</label>
                          <p className="text-gray-500">We're assuming you'd like to know if you've won a prize. Watch your email to be able to claim yours.</p>
                        </div>
                      </div>
                      <div className="mt-4">
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input 
                              id="moreInfo" 
                              type="checkbox" 
                              className="form-checkbox h-4 w-4 text-orange-600 transition duration-150 ease-in-out"
                              checked={props.user.moreInfo}
                              onChange={(e) => {
                                props.setUser({
                                  ...props.user,
                                  moreInfo: !props.user.moreInfo
                                });
                              }}
                            />
                          </div>
                          <div className="ml-3 text-sm leading-5">
                            <label htmlFor="moreInfo" className="font-medium text-gray-700">About The Information Lab</label>
                            <p className="text-gray-500">We do awesome stuff with Tableau, Alteryx & AWS (not just virtual pinball). Would you like to find out more?</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 border-t border-gray-200 pt-5">
        <div className="flex justify-end">
          <span className="inline-flex rounded-md shadow-sm">
            <button onClick={() => props.cancel()} type="button" className="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
              Cancel
            </button>
          </span>
          <span className="ml-3 inline-flex rounded-md shadow-sm">
            <button onClick={() => props.submitScore()} disabled={props.loading || !validForm} className={(validForm ? "bg-orange-600 hover:bg-orange-500" : "bg-gray-600 hover:bg-gray-500")  + "inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition duration-150 ease-in-out"}>
            {props.loading &&
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            }
            Submit
            </button>
          </span>
        </div>
      </div>
    </div>
  )
};

export default ScoreForm;
