import React from 'react';
import { Transition } from '@tailwindui/react';
import HauntedHouse from '../../img/hauntedHouse.gif';
import SpaceAdventure from '../../img/spaceAdventure.gif';
import { Link } from 'react-router-dom';
//import { Test } from './SelectGame.styles';

const SelectGame = (props) => {

  return (
  <div className="SelectGameWrapper">
    <Transition
      show={props.show}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      {(ref) => (
        <div className={`fixed z-10 inset-0 overflow-y-auto`}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div ref={ref} className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div> 
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <Transition
                show={props.show}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                {(ref) => (
                  <div ref={ref} className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                      <button onClick={props.toggle} type="button" className="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150" aria-label="Close">
                        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-center">
                        <div className="flex flex-inline mt-4">
                          <svg className="h-10 w-10 text-orange-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                          <h2 className="text-2xl leading-6 font-medium text-gray-900 ml-6 pt-2" id="modal-headline">
                            Select Pinball Game
                          </h2>
                        </div>
                        <div className="mt-10">
                        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                          <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow">
                            <Link to="/spaceadventure" onClick={props.toggle} >
                              <img src={SpaceAdventure} alt="Space Adventure" className="object-cover min-w-full min-h-full rounded-lg shadow"/>
                            </Link>
                          </li>
                          <li className="hidden sm:flex col-span-1 flex-col text-center bg-white pt-4">
                            <Link to="/hauntedhouse" onClick={props.toggle} >
                              <img src={HauntedHouse} alt="Haunted House" className="object-cover min-w-full min-h-full rounded-lg shadow my-auto"/>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Transition>
        </div>
      </div>
      )}
    </Transition>
  </div>
)};

export default SelectGame;
