import React, { useState } from 'react';
import Header from '../Header/Header';
import SelectGame from '../SelectGame/SelectGame';
import SubmitScore from '../SubmitScore/SubmitScore';
import ReactGA from 'react-ga';
//import { Test } from './SpaceAdventure.styles';

const SpaceAdventure = (props) => {
  const [score, setScore] = useState({
    game: 'Space Adventure',
    highScore: null,
    screenshot: null
  });
  ReactGA.pageview('/spaceadventure');
  return (
    <div className="SpaceAdventureWrapper">
      <div className="relative bg-gray-100">
        <Header toggleGameSelector={props.toggleGameSelector} toggleSubmitScore={props.toggleSubmitScore} showScoreSubmit={true}/>
        <iframe
          id="iframe_main"
          title="spaceAdventure"
          src="https://toytheater.com/space-pinball.php"
          style={{height: 'calc(100vh - 88px)', width: '100%', border: 0}}></iframe>
      </div>
      <SelectGame show={props.showGameSelector} toggle={props.toggleGameSelector}/>
      <SubmitScore 
          show={props.showSubmitScore}
          toggle={props.toggleSubmitScore}
          user={props.user}
          setUser={props.setUser}
          game={'Space Adventure'}
          score={score}
          updateScore={(name, val) => {
            const sc = {...score};
            sc[name] = val;
            setScore(sc)}} />
    </div>
  )
};

export default SpaceAdventure;
