import React, { useState } from 'react';
import { Transition } from '@tailwindui/react';
// import { Link } from 'react-router-dom';
import ScoreForm from './ScoreForm/ScoreForm';
import axios from 'axios';
import ReactGA from 'react-ga';

function getDisplayMedia(options) {
  if (navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia) {
      return navigator.mediaDevices.getDisplayMedia(options)
  }
  if (navigator.getDisplayMedia) {
      return navigator.getDisplayMedia(options)
  }
  if (navigator.webkitGetDisplayMedia) {
      return navigator.webkitGetDisplayMedia(options)
  }
  if (navigator.mozGetDisplayMedia) {
      return navigator.mozGetDisplayMedia(options)
  }
  console.log("ERROR: Can't get display media");
}

function getUserMedia(options) {
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      return navigator.mediaDevices.getUserMedia(options)
  }
  if (navigator.getUserMedia) {
      return navigator.getUserMedia(options)
  }
  if (navigator.webkitGetUserMedia) {
      return navigator.webkitGetUserMedia(options)
  }
  if (navigator.mozGetUserMedia) {
      return navigator.mozGetUserMedia(options)
  }
  console.log("ERROR: Can't get user media");
}

async function takeScreenshotStream() {
  // see: https://developer.mozilla.org/en-US/docs/Web/API/Window/screen
  const width = (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) * (window.devicePixelRatio || 1)
  const height = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) * (window.devicePixelRatio || 1)

  const errors = []
  let stream
  try {
      stream = await getDisplayMedia({
          audio: false,
          // see: https://developer.mozilla.org/en-US/docs/Web/API/MediaStreamConstraints/video
          video: {
              width,
              height,
              frameRate: 1,
          },
      })
  } catch (ex) {
      errors.push(ex)
  }

  // for electron js
  if (navigator.userAgent.indexOf('Electron') >= 0) {
      try {
          stream = await getUserMedia({
              audio: false,
              video: {
                  mandatory: {
                      chromeMediaSource: 'desktop',
                      // chromeMediaSourceId: source.id,
                      minWidth         : width,
                      maxWidth         : width,
                      minHeight        : height,
                      maxHeight        : height,
                  },
              },
          })
      } catch (ex) {
          errors.push(ex)
      }
  }

  if (errors.length) {
      console.debug(...errors)
      if (!stream) {
          throw errors[errors.length - 1]
      }
  }

  return stream
}

async function takeScreenshotCanvas() {
  const stream = await takeScreenshotStream()

  // from: https://stackoverflow.com/a/57665309/5221762
  const video = document.createElement('video')
  const result = await new Promise((resolve, reject) => {
      video.onloadedmetadata = () => {
          video.play()
          video.pause()

          // from: https://github.com/kasprownik/electron-screencapture/blob/master/index.js
          const canvas = document.createElement('canvas')
          canvas.width = video.videoWidth
          canvas.height = video.videoHeight
          const context = canvas.getContext('2d')
          // see: https://developer.mozilla.org/en-US/docs/Web/API/HTMLVideoElement
          context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
          resolve(canvas)
      }
      video.srcObject = stream
  })

  stream.getTracks().forEach(function (track) {
      track.stop()
  })
  
  if (result == null) {
      console.log("ERROR: Cannot take canvas screenshot");
  }

  return result
}

// from: https://stackoverflow.com/a/46182044/5221762
function getJpegBlob(canvas) {
  return new Promise((resolve, reject) => {
      // docs: https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob
      canvas.toBlob(blob => resolve(blob), 'image/jpeg', 0.95)
  })
}

async function takeScreenshotJpegBlob() {
  const canvas = await takeScreenshotCanvas()
  return getJpegBlob(canvas)
}

const SubmitScore = (props) => {

  const [ loading, setLoading ] = useState(false);
  const [ submissionError, setSubmissionError ] = useState('');

  const cancelEntry = () => {
    props.updateScore('highScore', null);
    props.updateScore('screenshot', null);
    props.toggle();
  }

  const submitScore = () => {
    setLoading(true);
    let data = new FormData();

    data.append('game', props.score.game);
    data.append('highScore', props.score.highScore);
    data.append('screenshot', props.score.screenshot);
    data.append('firstName', props.user.firstName);
    data.append('surname', props.user.surname);
    data.append('country', props.user.country);
    data.append('email', props.user.email);
    data.append('ifWin', props.user.ifWin);
    data.append('moreInfo', props.user.moreInfo);
    
    axios.post('/api/score', data, {
          headers: {
              'Content-Type': 'multipart/form-data',
          },
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        cancelEntry();
        ReactGA.event({
          category: 'Submit Score',
          action: props.score.game,
          label: props.score.highScore
        });
      })
      .catch((err) => {
        console.log('[SubmitScore.jsx] Submit error:', err);
        setSubmissionError(err);
        setLoading(false);
      });
  }

  async function captureScreen() {
    props.toggle();
    var screenshotJpegBlob = await takeScreenshotJpegBlob()

    console.log('[SubmitScore.jsx] captureScreen', screenshotJpegBlob);
  
    props.updateScore('screenshot', screenshotJpegBlob);
    props.toggle();
  }

  function saveScreenshot(blob) {
    props.updateScore('screenshot', blob);
  }

  return (
  <div className="SubmitScoreWrapper">
    <Transition
      show={props.show && submissionError === ''}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      {(ref) => (
        <div className={`fixed z-10 inset-0 overflow-y-auto`}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div ref={ref} className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div> 
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <Transition
                show={props.show}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                {(ref) => (
                  <div ref={ref} className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                      <button onClick={() => cancelEntry()} type="button" className="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150" aria-label="Close">
                        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-center">
                        <div className="flex flex-inline mt-4">
                          <svg className="h-10 w-10 text-orange-600"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                          </svg>
                          <h2 className="text-2xl leading-6 font-medium text-gray-900 ml-6 pt-2" id="modal-headline">
                            Submit Your High Score
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="mt-10">
                      <ScoreForm 
                        user={props.user}
                        setUser={(user) => props.setUser(user)}
                        score={props.score}
                        captureScreen={captureScreen}
                        saveCapture={(blob) => saveScreenshot(blob)}
                        setScore={(highScore) => {
                          props.updateScore('highScore', highScore);
                        }}
                        submitScore={submitScore}
                        cancel={cancelEntry}
                        loading={loading}
                        />
                    </div>
                  </div>
                )}
              </Transition>
          </div>
        </div>
      )}
    </Transition>
  </div>
)};

SubmitScore.propTypes = {
  // bla: PropTypes.string,
};

SubmitScore.defaultProps = {
  // bla: 'test',
};

export default SubmitScore;
