import React, { useState } from 'react';
import Header from '../Header/Header';
import SelectGame from '../SelectGame/SelectGame';
import SubmitScore from '../SubmitScore/SubmitScore';
import ReactGA from 'react-ga';
//import { Test } from './SpaceAdventure.styles';

const HauntedHouse = (props) => {
  const [score, setScore] = useState({
    game: 'Haunted House',
    highScore: null,
    screenshot: null
  });
  ReactGA.pageview('/hauntedhouse');
  return (
    <div className="SpaceAdventureWrapper">
      <div className="relative bg-gray-100">
        <Header toggleGameSelector={props.toggleGameSelector} toggleSubmitScore={props.toggleSubmitScore} showScoreSubmit={true}/>
        <iframe
          id="iframe_main"
          title="hauntedHouse"
          src="https://hauntedhousepinball.vercel.app/hauntedhouse.html"
          style={{height: 'calc(100vh - 188px)', width: '100%', border: 0}}></iframe>
        <div className="w-screen text-right pr-5">
          <h4 className="text-xl">Instructions</h4>
          <ul className="text-sm">
            <li>Left flipper - Left Shift, Left Mouse Button</li>
            <li>Right flipper - Right Shift, Right Mouse Button</li>
            <li>Shootout - Space, Middle Mouse Button</li>
          </ul>
        </div>
      </div>
      <SelectGame show={props.showGameSelector} toggle={props.toggleGameSelector}/>
      <SubmitScore 
          show={props.showSubmitScore}
          toggle={props.toggleSubmitScore}
          user={props.user}
          setUser={props.setUser}
          game={'Haunted House'}
          score={score}
          updateScore={(name, val) => {
            const sc = {...score};
            sc[name] = val;
            setScore(sc)}} />
    </div>
  )
};

export default HauntedHouse;