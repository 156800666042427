import React from 'react';
import { Link } from 'react-router-dom';
import PinballImage from '../../img/pinball2.jpg';
import PlaygroundLogo from '../../img/data-playground.svg';
import SelectGame from '../SelectGame/SelectGame';
import Header from '../Header/Header';

const Home = (props) => {
  return (
    <div className="HomeWrapper">
      <div className="relative bg-gray-100">
        <Header toggleGameSelector={props.toggleGameSelector}/>
        <main className="lg:relative">
          <svg className="hidden lg:block absolute left-0 transform" width="414" height="790" fill="none" viewBox="0 0 414 790">
            <defs>
              <pattern id="svg-pattern-squares-1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <line stroke="#e2e8f0" className="text-gray-200" id="svg_1" y2="3" x2="6" y1="3" x1="0" strokeWidth="1.5" fill="currentColor"/>
                <line stroke="#e2e8f0" className="text-gray-200" id="svg_2" y2="6" x2="3" y1="0" x1="3" strokeWidth="1.5" fill="currentColor"/>
              </pattern>
            </defs>
            <rect width="414" height="790" fill="url(#svg-pattern-squares-1)" />
          </svg>
          <div className="relative mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
            <div className="z-2 px-4 lg:w-1/2 sm:px-8 xl:pr-16">
              <img className="w-fill md:w-3/4 md:mx-auto lg:mx-0" src={PlaygroundLogo} alt="The Data Playground" />
              <p className="mt-3 max-w-md mx-auto text-lg text-gray-600 sm:text-xl md:mt-5 md:max-w-3xl">
                Tableau Conference may be different this year but at The Information Lab we're determinded to make it unforgettable.
              </p>
              <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <div role="button" tabIndex={0}  onClick={props.toggleGameSelector} className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                    Choose your Game
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <img className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src={PinballImage} alt="Pinball Machine" />
          </div>    
        </main>
        <SelectGame show={props.showGameSelector} toggle={props.toggleGameSelector}/>
      </div>

    </div>
  )
}

export default Home;
